@mixin square($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin circle($size: 100%) {
  @include square($size);

  border-radius: 100%;
  overflow: hidden;
}

@mixin vertically-center-content() {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// A mixin to provide automatic image sources for @2x displays
// $file - your file name
// $type - the file extension
// $size - defaults to cover, could be set to any valid background size value
@mixin crux-at2x($file, $type, $size: cover) {
  background-image: url($file + '.' + $type);
  background-size: $size;

  @include on-retina {
    background-image: url($file + '@2x.' + $type);
    background-size: $size;
  }
}

@mixin glowing($duration, $color) {
  @keyframes glowing {
    0% {
      box-shadow: 0 0 10px $color;
    }

    50% {
      box-shadow: 0 0 1px $color;
    }

    100% {
      box-shadow: 0 0 10px $color;
    }
  }

  animation-duration: $duration;
  animation-name: glowing;
  animation-iteration-count: infinite;
}

@mixin background-blink($duration, $start-color, $end-color) {
  @keyframes backgroundFade {
    0% {
      background: $start-color;
    }

    50% {
      background: $end-color;
    }

    100% {
      background: $start-color;
    }
  }

  animation-duration: $duration;
  animation-name: backgroundFade;
  animation-iteration-count: infinite;
}
