// ----------------------------------------------------------------------------
// expanded localmed base colors
// ----------------------------------------------------------------------------

$white: #fff;
$true-black: #000;
$black: $charcoal;
$slight-white: #fafafa;

// color saturations
$blue-desaturated: #6dadc5;
$red-desaturated: #e06a5b;

// color tints
$blue-tinted-dark: #2c80a0;
$red-tinted-dark: #9b1b0b;
$green-tinted-dark: #038369;

// color shadows
$blue-shadow: #21627a;
$red-shadow: #6f1206;
$green-shadow: #035432;

// gray scales
$white-tinted-ten-percent: darken($white, 10%);
$white-tinted-twenty-percent: darken($white, 20%);
$white-tinted-thirty-percent: darken($white, 30%);
$white-tinted-forty-percent: darken($white, 40%);
$half-gray: darken($white, 50%);
$black-lightened-ten-percent: lighten($black, 10%);
$black-lightened-twenty-percent: lighten($black, 20%);
$black-lightened-thirty-percent: lighten($black, 30%);
$black-lightened-forty-percent: lighten($black, 40%);

// other grays
$alt-slight-white: #f2f2f2;
$light-gray: #e2e2e2;
$alt-border-color: #81807b;
$crux-text-gray: #585858;
