@import '../../common';

.appointment-form-page__title {
  @extend %strong;
  @include adjust-font-size-to($h1-font-size, $h1-line-height);
  font-family: $meta-bold-font-family;
  margin: rem-rhythm(1) 0 rem-rhythm(0.5);

  small {
    color: $muted-color;
    font-weight: normal;
    font-family: $meta-normal-font-family;
  }

  strong {
    color: $error-color;
  }
}

.appointment-form-page__subtitle {
  @include adjust-font-size-to($h3-font-size, $h3-line-height);
  font-family: $meta-normal-font-family;

  strong {
    font-family: $meta-bold-font-family;
  }
}

.appointment-form-page__map {
  display: block;
  border-color: $border-color;
  border-width: 1px 1px 0;
  border-style: solid;
}

.appointment-form-page__row {
  @include row-width;
  @include row-margin(rem-rhythm(0.5));
  padding-bottom: rem-rhythm(0.5);
}

.appointment-form-page__aside {
  margin-top: rem-rhythm(1);
}

@include min-screen($viewport-lg) {
  .appointment-form-page__title {
    margin: rem-rhythm(1) 0;
  }

  .appointment-form-page__row {
    @include row-margin;
    display: flex;
    padding-bottom: rem-rhythm(1);
  }

  .appointment-form-page__main {
    flex: 0 0 66.667%;
    max-width: 66.667%;
  }

  .appointment-form-page__aside {
    display: block;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-top: 0;
    padding-left: rem-rhythm(1);
  }

  .appointment-form-page__map {
    border-top: 0;
  }
}
