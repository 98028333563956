@import '../common';

.fieldset {
  padding: rem-rhythm(0.5);
  border: 0;
  border-top: 1px dashed $box-border-color;

  @include min-screen($viewport-md) {
    padding: rem-rhythm(1);
  }

  &:first-child {
    border: 0;
  }
}

.fieldset__legend {
  @extend %strong;
  @include adjust-font-size-to($h3-font-size, $h3-line-height);
  float: left;
  margin: 0 0 em-rhythm(0.5, $h3-font-size);
  font-family: $meta-bold-font-family;

  + * {
    clear: left;
  }
}

.fieldset--horizontal {
  @include min-screen($xs-screen) {
    // stylelint-disable-next-line max-nesting-depth
    .fieldset__legend {
      margin-left: calc(30% + #{rem-rhythm(1)});
    }
  }
}
