@import '../common';

.field {
  @include spacing(1);
}

.field__label {
  @include adjust-font-size-to($small-font-size);
}

.field--horizontal {
  @include min-screen($xs-screen) {
    display: flex;

    // stylelint-disable-next-line max-nesting-depth
    .field__label {
      display: block;
      flex-basis: 30%;
      max-width: 30%;
      padding-top: rem-rhythm(0.25);
      text-align: right;
    }

    // stylelint-disable-next-line max-nesting-depth
    .field__body {
      margin-left: auto;
      padding-left: rem-rhythm(1);
      flex-basis: 70%;
      max-width: 70%;
    }
  }
}
