@import './common';

.calendar-table-cell {
  height: rhythm($calendar-opening-line-height);
  padding: 0;
  text-align: center;
  vertical-align: baseline;
}

.calendar-table-cell--starts-week {
  border-left: 1px dashed $muted-color;
}
