@import '../../common';

.main-layout__body {
  flex: 1 0 auto;
}

.main-layout__row {
  @include row-width;
  @include row-margin;
  padding-top: rem-rhythm(1);
  padding-bottom: rem-rhythm(1);
}
