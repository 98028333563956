@import '../../common';

.info {
  overflow-x: auto;
}

.info__row {
  @include spacing;
  display: flex;
}

.info__row--border {
  border-bottom: 1px solid $border-color;
  padding-bottom: rem-rhythm(0.5);
}

.info__dt {
  flex: 0 0 em-rhythm(3.5);
  text-align: center;
}

.info__dd {
  flex: auto;
  padding-right: rem-rhythm(0.5);
  min-width: 0;
}

.info__photo {
  display: inline-block;
  width: em-rhythm(2);
  height: em-rhythm(2);
}

.info__icon-circle {
  display: inline-block;
}

.info__h1 {
  @extend %strong;
  @include adjust-font-size-to($h3-font-size, 1);
  font-family: $meta-bold-font-family;
}

.info__h2 {
  @extend %strong;
  @extend %small;
  display: block;
  font-family: $meta-bold-font-family;
}

.info__line {
  @extend %small;
  display: block;
}

.info__address {
  font-style: normal;
}

.info--large {
  @include adjust-font-size-to($h3-font-size, $h3-line-height);

  .info__photo {
    width: em-rhythm(3, $h3-font-size);
    height: em-rhythm(3, $h3-font-size);
  }
}
