// Set `$inline-block-alignment` to `none` or `false` to disable the output
// of a vertical-align property in the inline-block mixin.
// Or set it to a legal value for `vertical-align` to change the default.
$inline-block-alignment: middle !default;

// Provides a cross-browser method to implement `display: inline-block;`
@mixin inline-block($alignment: $inline-block-alignment) {
  @if $legacy-support-for-mozilla {
    display: -moz-inline-stack;
  }
  display: inline-block;
  @if $alignment and $alignment != none {
    vertical-align: $alignment;
  }
  @if $legacy-support-for-ie {
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
  }
}

// Provides cross-browser CSS opacity. Takes a number between 0 and 1 as the argument, e.g. 0.5 for 50% opacity.
//
//     @param $opacity
//         A number between 0 and 1, where 0 is transparent and 1 is opaque.
@mixin opacity($opacity) {
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 or $legacy-support-for-ie8 {
    filter: unquote('progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})');
  }
  opacity: $opacity;
}
