@import '../common';

:global {
  // stylelint-disable-next-line selector-no-type
  body {
    background-color: $white;
  }
}

.widget-layout__container {
  padding: rem-rhythm(0.5);
  margin: 0 auto;
}

.widget-layout__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
  width: 100%;
}

.widget-layout__call-to-action {
  background-image: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 1) 50%);
}

.widget-layout__call-to-action-inner {
  @include row-width;
  padding: rem-rhythm(2) rem-rhythm(0.5) rem-rhythm(0.25);
}

.widget-layout--embedded {
  border-top: 4px solid $blue;
}
