@import '../../common';

.office-calendar {
  @include row-width;
  @include min-screen($viewport-sm) {
    padding: rem-rhythm(0.5);
  }
}

.office-calendar__header {
  background: $white;
  @include min-screen($viewport-sm) {
    border: 1px solid $border-color;
    border-bottom: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  @include min-screen($viewport-md) {
    display: flex;
  }
}

.office-calendar__header-content {
  padding: rem-rhythm(0.5);
  flex: 1;
  position: relative;
  z-index: 100;
}

.office-calendar__first-available {
  padding: 0 rem-rhythm(0.5) rem-rhythm(0.5);

  @include min-screen($viewport-md) {
    padding: rem-rhythm(0.75) rem-rhythm(0.5) rem-rhythm(0.5);
  }
}

.office-calendar__calendar {
  @include min-screen($viewport-sm) {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.no-penings-for-status {
  position: relative;
  border: 1px solid $border-color;
  border-bottom: 4px solid $border-color;
  text-align: center;
  padding: rem-rhythm(1);
  background-color: $white;
}
