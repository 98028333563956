@import '../common';

.widget-ratings {
  @include flex;

  width: auto;
  flex-flow: row wrap;
  justify-content: center;
  padding: rem-rhythm(0.333);
  margin-bottom: 0;
  list-style: none;
  background: $slight-white;
  border-bottom: 1px solid $border-color;
}

.widget-ratings__item {
  margin: em-rhythm(0.25);
}
