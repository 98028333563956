@import '../common';

$progress-background-color: $input-background-color;
$progress-bar-color: $blue;
$progress-color: #fff;
$progress-height: $input-height;
$progress-border-radius: $input-border-radius;
$progress-box-shadow-color: $input-box-shadow-color;
$progress-value-font-size: 18px;
$success-progress-bar-color: $success-color;
$warning-progress-bar-color: $warning-color;
$error-progress-bar-color: $error-color;
$small-progress-height: 0.25;

.password-input__strength-indicator {
  box-shadow: inset 0 1px 1px $progress-box-shadow-color;
  overflow: hidden;
  height: rem-rhythm(0.25);
  position: relative;
  color: $progress-bar-color;
  border: 1px solid currentColor;
  background: $progress-background-color;
  transition: border-color 100ms linear;
}

.password-input__strength-indicator-bar {
  float: left;
  width: 0%;
  height: 100%;
  background-color: currentColor;
  transition: width 100ms ease-out, background-color 100ms linear;
}

.password-input__strength-indicator--0 {
  color: $error-color;

  .password-input__strength-indicator-bar {
    width: 20%;
  }
}

.password-input__strength-indicator--1 {
  color: $error-color;

  .password-input__strength-indicator-bar {
    width: 40%;
  }
}

.password-input__strength-indicator--2 {
  color: $warning-color;

  .password-input__strength-indicator-bar {
    width: 60%;
  }
}

.password-input__strength-indicator--3 {
  color: $blue;

  .password-input__strength-indicator-bar {
    width: 80%;
  }
}

.password-input__strength-indicator--4 {
  color: $success-color;

  .password-input__strength-indicator-bar {
    width: 100%;
  }
}
