@import './localmed-icons';
@import './localmed-icons-basic';
@import '../vendor/compass/vertical-rhythm';

// ----------------------------------------------------------------------------
// Base Settings
// ----------------------------------------------------------------------------

$base-font-family: Arial, Verdana, sans-serif;
$base-font-size: 15px;
$base-line-height: 24px;
$indent-amount: rhythm(1);

// ----------------------------------------------------------------------------
// Fonts
// ----------------------------------------------------------------------------

$meta-normal-font-family: 'MetaWeb-Normal', Helvetica, Arial, sans-serif;
$meta-bold-font-family: 'MetaWeb-Bold', Helvetica, Arial, sans-serif;
$meta-sc-normal-font-family: 'MetaScWeb-Normal', Helvetica, Arial, sans-serif;
$meta-sc-bold-font-family: 'MetaScWeb-Bold', Helvetica, Arial, sans-serif;
$icons-font-family: 'LocalMed-Icons', 'LocalMed-Icons-Basic', Arial, sans-serif;

// ----------------------------------------------------------------------------
// Typography
// ----------------------------------------------------------------------------

// Size Variations
$big: 42px 2;
$small: 13px 0.8;

// Headings
$h1: 32px 1.5;
$h2: 26px 1.375;
$h3: 20px 1.25;
$h4: 16px 1;
$h5: 14px 0.9;
$h6: 13px 0.8;

// Mobile Headings
$mobile-h1: 24px 1.375;
$mobile-h2: 20px 1.25;
$mobile-h3: 18px 1.125;

// font-size, line-height for all variations
$big-font-size: nth($big, 1);
$big-line-height: nth($big, 2);
$small-font-size: nth($small, 1);
$small-line-height: nth($small, 2);
$h1-font-size: nth($h1, 1);
$h1-line-height: nth($h1, 2);
$h2-font-size: nth($h2, 1);
$h2-line-height: nth($h2, 2);
$h3-font-size: nth($h3, 1);
$h3-line-height: nth($h3, 2);
$h4-font-size: nth($h4, 1);
$h4-line-height: nth($h4, 2);
$h5-font-size: nth($h5, 1);
$h5-line-height: nth($h5, 2);
$h6-font-size: nth($h6, 1);
$h6-line-height: nth($h6, 2);
$mobile-h1-font-size: nth($mobile-h1, 1);
$mobile-h1-line-height: nth($mobile-h1, 2);
$mobile-h2-font-size: nth($mobile-h2, 1);
$mobile-h2-line-height: nth($mobile-h2, 2);
$mobile-h3-font-size: nth($mobile-h3, 1);
$mobile-h3-line-height: nth($mobile-h3, 2);

// ----------------------------------------------------------------------------
// Colors
// ----------------------------------------------------------------------------

// Primary Colors
$red: #d22a15;
$blue: #2f8aac;
$green: #009e7e;
$charcoal: #2e2c26;
$charcoal-gray: #57554f;

// UI Specific Colors
$text-color: $charcoal-gray;
$strong-color: $charcoal;
$background-color: #f0efed;
$border-color: #d8d8d8;
$rule-color: #ccc;
$muted-color: #999;
$primary-color: $blue;
$success-color: $green; // #009e45;
$warning-color: #d1892a;
$error-color: $red; // #d21515;

// ----------------------------------------------------------------------------
// Transitions
// ----------------------------------------------------------------------------

$in-duration: 0.2s;
$out-duration: 0.5s;
$default-transition-property: all;
$default-transition-duration: $out-duration;
$default-transition-function: ease-out;

// ----------------------------------------------------------------------------
// Sizes and Radii
// ----------------------------------------------------------------------------

$space: 0.5;
$full-space: 1;
$extra-space: 2;
$horizontal-component-left: 25%;
$horizontal-component-right: 70%;
$horizontal-component-margin-left: 100% - $horizontal-component-right;
$border-radius: 4px;
$small-border-radius: 2px;
$box-shadow-size: 3px;
$small-box-shadow-size: 2px;
$mobile-breakpoint: 480px;
$tablet-breakpoint: 800px;
$triangle-size: 6px;

// ----------------------------------------------------------------------------
// Links
// ----------------------------------------------------------------------------

$link-color: $blue;
$link-visited-color: $blue; // mix($blue, $red, 80%);
$link-hover-color: $red;
$link-active-color: $strong-color;

// ----------------------------------------------------------------------------
// Inversed Backgrounds
// ----------------------------------------------------------------------------

$inverse-background-color: $charcoal;
$inverse-text-color: #fff;
$inverse-link-color: #69a6bd;

// ----------------------------------------------------------------------------
// Forms
// ----------------------------------------------------------------------------

$input-font-size: 14px;
// Firefox overwrites line-height with normal !important, so we’ll just make
// sure the line-height is consistent across the other browsers.
$input-line-height: 0.75;
$input-height: 1.25;
$input-horizontal-padding: 0.375;
$input-vertical-padding: 0.25;
$input-background-color: #fff;
$input-text-color: $strong-color;
$input-disabled-background-color: darken($input-background-color, 5%);
$input-disabled-text-color: lighten($text-color, 10%);
$input-border-color: $rule-color;
$input-border-radius: $border-radius;
$input-box-shadow-color: $input-border-color;
$input-gray-background-color: #f7f7f7;
$input-gray-disabled-background-color: darken($input-gray-background-color, 5%);
$all-text-inputs: 'input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"]';

// ----------------------------------------------------------------------------
// Boxes
// ----------------------------------------------------------------------------

// Boxes
$box-border-radius: $border-radius;
$box-background-color: #fff;
$box-border-color: $border-color;
$box-shadow-color: $box-border-color;
$box-header-background-color: $charcoal-gray;

// Small Boxes
$small-box-border-radius: $small-border-radius;

// Subtle Boxes
$subtle-box-background-color: #eaeaea;
$subtle-box-border-color: $rule-color;
$subtle-box-shadow-color: $subtle-box-border-color;

// Dark Boxes
$dark-box-background-color: $charcoal-gray;
$dark-box-text-color: #fff;
$dark-box-shadow-color: $charcoal;

// ----------------------------------------------------------------------------
// Tables
// ----------------------------------------------------------------------------

$table-border-radius: $border-radius;
$table-head-font-size: $small-font-size;
$table-head-background-color: $charcoal-gray;
$table-with-foot-shadow-color: $dark-box-shadow-color;
$table-striped-background-color: #f6f6f6;
$table-hover-background-color: $table-striped-background-color;

// Data Tables
$data-table-head-background-color: $subtle-box-background-color;
$data-table-head-hoverbackground-color: lighten($data-table-head-background-color, 5%);
$data-table-head-border-color: $subtle-box-border-color;
$data-table-sort-indicator-icon-font-size: 22px;

// ----------------------------------------------------------------------------
// Buttons
// ----------------------------------------------------------------------------

$button-font-size: $input-font-size;
$button-line-height: 1;
$button-icon-font-size: $small-font-size;
$button-border-radius: $border-radius;
$button-box-shadow-size: $small-box-shadow-size;
$button-background-color: $subtle-box-background-color;
$button-hover-background-color: lighten($button-background-color, 10%);
$button-border-color: $subtle-box-border-color;
$button-shadow-color: $subtle-box-shadow-color;
$button-text-color: $text-color;

// Small Buttons
$small-button-font-size: 11px;
$small-button-line-height: $small-line-height;
$small-button-border-radius: $small-border-radius;

// Big Buttons
$big-button-font-size: 16px;
$big-button-line-height: $h3-line-height;
$big-button-border-radius: $border-radius;
$big-button-box-shadow-size: $box-shadow-size;

// Alt Buttons
$alt-button-background-color: lighten($charcoal-gray, 15%);
$alt-button-hover-background-color: lighten($alt-button-background-color, 10%);
$alt-button-border-color: darken($alt-button-background-color, 12.5%);
$alt-button-shadow-color: $alt-button-border-color;
$alt-button-text-color: #fff;

// Primary Buttons
$primary-button-background-color: $blue;
$primary-button-hover-background-color: lighten($primary-button-background-color, 10%);
$primary-button-border-color: darken($primary-button-background-color, 12.5%);
$primary-button-shadow-color: $primary-button-border-color;
$primary-button-text-color: #fff;

// Alert Buttons
$alert-button-background-color: $red;
$alert-button-hover-background-color: lighten($alert-button-background-color, 10%);
$alert-button-border-color: darken($alert-button-background-color, 12.5%);
$alert-button-shadow-color: $alert-button-border-color;
$alert-button-text-color: #fff;

// Button Groups
$button-group-active-box-shadow: inset 0 1px 4px;

// ----------------------------------------------------------------------------
// Messages
// ----------------------------------------------------------------------------

$message-border-radius: $small-border-radius;
$message-icon-font-size: $h3-font-size;
$small-message-icon-font-size: $base-font-size;

// Primary Messages
$primary-message-background-color: change-color($primary-color, $saturation: 50%, $lightness: 95%);
$primary-message-border-color: change-color($primary-color, $saturation: 36%, $lightness: 79%);
$primary-message-text-color: change-color($primary-color, $saturation: 15%, $lightness: 37%);

// Success Messages
$success-message-background-color: change-color($success-color, $saturation: 50%, $lightness: 95%);
$success-message-border-color: change-color($success-color, $saturation: 36%, $lightness: 79%);
$success-message-text-color: change-color($success-color, $saturation: 15%, $lightness: 37%);

// Warning Messages
$warning-message-background-color: change-color($warning-color, $saturation: 50%, $lightness: 95%);
$warning-message-border-color: change-color($warning-color, $saturation: 36%, $lightness: 79%);
$warning-message-text-color: change-color($warning-color, $saturation: 15%, $lightness: 37%);

// Error Messages
$error-message-background-color: change-color($error-color, $saturation: 50%, $lightness: 95%);
$error-message-border-color: change-color($error-color, $saturation: 36%, $lightness: 79%);
$error-message-text-color: change-color($error-color, $saturation: 15%, $lightness: 37%);

// Inverse Messages
$inverse-message-text-color: $inverse-text-color;
$inverse-primary-message-background-color: change-color($primary-color, $saturation: 70%);
$inverse-success-message-background-color: change-color($success-color, $saturation: 70%);
$inverse-warning-message-background-color: change-color($warning-color, $saturation: 70%);
$inverse-error-message-background-color: change-color($error-color, $saturation: 70%);

// ----------------------------------------------------------------------------
// Dropdowns
// ----------------------------------------------------------------------------

$dropdown-z-index: 35;
$dropdown-border-radius: $border-radius;
$dropdown-font-size: $small-font-size;
$dropdown-hover-background-color: $subtle-box-background-color;
$dropdown-hover-text-color: $link-hover-color;

// ----------------------------------------------------------------------------
// Tooltips
// ----------------------------------------------------------------------------

$tooltip-font-size: $small-font-size;
$tooltip-line-height: rhythm($small-line-height, $tooltip-font-size);
$tooltip-height: rhythm(1.3, $tooltip-font-size);
$tooltip-width: rhythm(6, $tooltip-font-size);
$tooltip-arrow-width: 6px;
$tooltip-border-radius: $small-border-radius;
$tooltip-transition-distance: 8px;
$tooltip-background-color: $charcoal;
$tooltip-color: #fff;
$success-tooltip-background-color: $success-color;
$warning-tooltip-background-color: $warning-color;
$error-tooltip-background-color: $error-color;
$narrow-tooltip-width: rhythm(3, $tooltip-font-size);

// ----------------------------------------------------------------------------
// Tabs
// ----------------------------------------------------------------------------

$tabs-font-size: $small-font-size;
$tabs-border-radius: $small-border-radius;
$tabs-background-color: $subtle-box-background-color;
$tabs-text-color: $link-color;
$tabs-hover-background-color: $border-color;
$tabs-hover-text-color: $link-hover-color;
$tabs-active-background-color: $primary-button-background-color;
$tabs-active-text-color: $primary-button-text-color;
$box-tabs-background-color: darken($subtle-box-background-color, 3%);

// ----------------------------------------------------------------------------
// Breadcrumbs
// ----------------------------------------------------------------------------

$breadcrumbs-font-size: $small-font-size;
$breadcrumbs-divider-font-size: 10px;
$breadcrumbs-background-color: $subtle-box-background-color;
$breadcrumbs-border-color: $subtle-box-border-color;
$breadcrumbs-hover-background-color: $primary-button-hover-background-color;
$breadcrumbs-hover-text-color: $primary-button-text-color;

// ----------------------------------------------------------------------------
// Labels
// ----------------------------------------------------------------------------

$label-font-size: 11px;
$label-line-height: 1;
$label-background-color: $button-background-color;
$label-text-color: $button-text-color;
$label-border-radius: $small-border-radius;

// Alt Labels
$alt-label-background-color: lighten($charcoal-gray, 15%);
$alt-label-text-color: #fff;

// Primary Labels
$primary-label-background-color: $blue;
$primary-label-text-color: #fff;

// Alert Labels
$alert-label-background-color: $red;
$alert-label-text-color: #fff;

// ----------------------------------------------------------------------------
// Pagination
// ----------------------------------------------------------------------------

$pagination-background-color: #fff;
