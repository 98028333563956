@import '../../common';

.change-button {
  @extend %caps;
  display: block;
  padding: legacy-rem(0.8) legacy-rem(0.5) 0;
  font-size: legacy-rem(0.55);
  line-height: legacy-rem(0.75);
  color: $white;
  text-align: center;
  background: $blue-desaturated;

  &:focus,
  &:hover {
    color: $white;
    background: $blue-shadow;
  }

  // stylelint-disable-next-line order/order
  @include min-screen($s-screen) {
    padding-right: legacy-rem(1);
    padding-left: legacy-rem(1);
  }
}

.change-button__icon {
  font-size: legacy-rem(0.75);
}
