@import '../common';

.star-rating {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.star-rating__filled {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  overflow: hidden;

  .star-rating__icon {
    color: $star-rating-color;
  }
}

.star-rating__empty {
  position: relative;
  z-index: 1;

  .star-rating__icon {
    color: $rule-color;
  }
}
