@import '../common';

.fade {
  transition: opacity 300ms ease-out;
  opacity: 0;
}

.fade--entering {
  opacity: 0;
}

.fade--entered {
  opacity: 1;
}
