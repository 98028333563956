@import '../common';

.legacy-panel {
  margin-top: 0;
  margin-bottom: rem-rhythm(1);
  padding: rem-rhythm(0.5);
  border: 1px solid $box-border-color;
  border-bottom-width: $small-box-shadow-size + 1px;
  background: $box-background-color;
  border-radius: $small-box-border-radius;
}

.legacy-panel--gray {
  background: $input-gray-background-color;
}

.legacy-panel--large {
  padding: rem-rhythm(1);
  border-radius: $box-border-radius;
}
