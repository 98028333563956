@import '../../common';

.insurance-form__sideways {
  display: flex;
  flex-direction: row;

  > * {
    width: 50%;
  }

  > *:last-child {
    padding-left: rem-rhythm(0.5);
  }
}

.insurance-form__section {
  margin-top: rem-rhythm(0.5);
}

@include min-screen($viewport-sm) {
  .insurance-form {
    display: flex;
    justify-content: space-between;
  }

  .insurance-form__section {
    margin-top: 0;
    width: 50%;

    &:last-of-type {
      padding-left: rem-rhythm(1);
    }
  }

  .insurance-form__button {
    margin-top: 1.1rem;
  }
}
