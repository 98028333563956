@import '../../common';

$widget-header-icon-size: 35px;
$widget-header-photo-size: 35px;

.header {
  background: $blue;
  position: relative;
  z-index: 20;
}

.header__container {
  margin: 0 auto;
}

.header__row {
  @include flex;

  flex-flow: row nowrap;
  min-height: $widget-header-photo-size + $legacy-base-font-size;
}

.header__figure {
  padding: legacy-rem(0.5) 0;
  margin-left: legacy-rem(0.75);
}

.header__icon {
  @include square($widget-header-icon-size);

  color: $white;
}

.header__photo {
  @include circle($widget-header-photo-size);

  display: block;
  text-align: center;
  background: $white;
  border: 1px solid $white;
  box-shadow: 0 0 3px 1px darken($blue, 10%);
}

.header__content {
  flex: 1;
  padding: legacy-rem(1.2) legacy-rem(0.75) legacy-rem(0.5);
}

.header__title {
  margin: 0;
  font-family: $meta-bold-font-family;
  font-size: legacy-rem(1.3);
  line-height: legacy-rem(1.3);
  color: $white;
}

.header__subtitle {
  margin: 0;
  font-size: legacy-rem(0.9);
  line-height: legacy-rem(1.2);
  color: $white;

  @include min-screen($m-screen) {
    margin-left: legacy-rem(1);
  }
}

.header--with-subtitle {
  .header__content {
    padding-top: legacy-rem(0.65);

    // stylelint-disable-next-line max-nesting-depth
    @include min-screen($m-screen) {
      display: flex;
      align-items: baseline;
      padding-top: legacy-rem(1.2);
    }
  }
}
