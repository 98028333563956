// stylelint-disable selector-no-type

@import './common';

@font-face {
  font-family: MetaWeb-Normal;
  src: url('https://assets.localmed.com/shared/fonts/MetaWeb-Normal.eot');
  src: url('https://assets.localmed.com/shared/fonts/MetaWeb-Normal.eot?#iefix')
      format('embedded-opentype'),
    url('https://assets.localmed.com/shared/fonts/MetaWeb-Normal.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: MetaWeb-Bold;
  src: url('https://assets.localmed.com/shared/fonts/MetaWeb-Bold.eot');
  src: url('https://assets.localmed.com/shared/fonts/MetaWeb-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('https://assets.localmed.com/shared/fonts/MetaWeb-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: MetaWeb;
  src: url('https://assets.localmed.com/shared/fonts/MetaWeb-Normal.eot');
  src: url('https://assets.localmed.com/shared/fonts/MetaWeb-Normal.eot?#iefix')
      format('embedded-opentype'),
    url('https://assets.localmed.com/shared/fonts/MetaWeb-Normal.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: MetaWeb;
  src: url('https://assets.localmed.com/shared/fonts/MetaWeb-Bold.eot');
  src: url('https://assets.localmed.com/shared/fonts/MetaWeb-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('https://assets.localmed.com/shared/fonts/MetaWeb-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}

html,
body {
  height: 100%;
}

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: em-rhythm(1);
  color: $text-color;
  background: $white;
}

// Use border-box box sizing by default

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::selection {
  background: change-color($blue, $saturation: 50%, $lightness: 90%);
}

::placeholder {
  color: $muted-color;
}

@include shelves-responsive-base;

a {
  color: $link-color;
  transition-timing-function: ease-out;
  transition-duration: $out-duration;
  transition-property: background-color, border-color, color;
  cursor: pointer;

  &:link {
    text-decoration: none;
    transition-duration: 0;
  }

  &:visited {
    text-decoration: none;
    transition-duration: 0;
  }

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
    transition-duration: $in-duration;
  }

  &:active {
    color: $link-active-color;
    text-decoration: none;
    transition-duration: 0;
  }
}

p,
ul,
ol,
dl {
  @include spacing;
}
