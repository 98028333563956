@import '../../common';

.office-reviews-page__review-list {
  margin: 0;
  padding: 0;
  list-style: none;

  @include on-tablet-up {
    @include row-width;
    display: flex;
    flex-wrap: wrap;
    padding: rem-rhythm(0.25);
  }
}

.office-reviews-page__review {
  border-bottom: 1px solid $border-color;

  &:last-child {
    border-bottom: 0;
  }

  // stylelint-disable-next-line order/order
  @include on-tablet-up {
    flex-grow: 1;
    width: 50%;
    padding: rem-rhythm(0.25);
    border-bottom: 0;
  }

  @include on-desktop {
    width: 33%;
  }
}
