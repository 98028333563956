@import '../../common';

.provider-card-header {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid $border-color;
  background: $slight-white;
}

.provider-card-header__image {
  flex-shrink: 0;
  width: 60px;
  border-right: 1px solid $border-color;

  img {
    vertical-align: bottom;
    width: 100%;
  }
}

.provider-card-header__content {
  padding: rem-rhythm(0.5) rem-rhythm(0.5) 0;
  overflow: hidden;
}
