@import '../../common';

.cancel-appointment-page__row {
  @include row-width;
  @include row-margin(rem-rhythm(0.5));
  margin-bottom: rem-rhythm(0.5);
}

.cancel-appointment-page__main {
  margin: rem-rhythm(0.5) 0 rem-rhythm(1);
}

.cancel-appointment-page__map {
  display: block;
  border-color: $border-color;
  border-width: 1px 1px 0;
  border-style: solid;
}

@include min-screen($viewport-lg) {
  .cancel-appointment-page__row {
    @include row-margin;
    display: flex;
    margin-bottom: rem-rhythm(1);
  }

  .cancel-appointment-page__main {
    flex: 0 0 66.667%;
    max-width: 66.667%;
    margin-top: rem-rhythm(1);
  }

  .cancel-appointment-page__aside {
    display: block;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding-left: rem-rhythm(1);
  }

  .cancel-appointment-page__map {
    border-top: 0;
  }
}
