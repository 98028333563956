@import '../common';

.icon-circle {
  width: em-rhythm(1);
  height: em-rhythm(1);
  border-radius: 100%;
  position: relative;
  background: $border-color;
}

.icon-circle__icon {
  width: 0.85em;
  height: 0.85em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-circle--red {
  background: $red;
  color: $white;
}

.icon-circle--blue {
  background: $blue;
  color: $white;
}
