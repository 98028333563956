%strong {
  color: $strong-color;
  font-weight: bold;
}

%muted {
  color: $muted-color;
  font-weight: normal;
}

%caps {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

%no-caps {
  text-transform: none;
  letter-spacing: 0;
}

%big {
  @media screen and (min-width: $tablet-breakpoint) {
    @include adjust-font-size-to($big-font-size, $big-line-height);
  }
}

%small {
  @include adjust-font-size-to($small-font-size, $small-line-height);
}

%no-wrap {
  white-space: nowrap;
}

%ellipsis {
  @include ellipsis;
}

%text-left {
  text-align: left;
}

%text-center {
  text-align: center;
}

%text-right {
  text-align: right;
}

%text-justify {
  text-align: justify;
}

%primary {
  color: $primary-color;
}

%success {
  color: $success-color;
}

%warning {
  color: $warning-color;
}

%error {
  color: $error-color;
}

%big-hr {
  margin-top: rhythm($full-space);
  margin-bottom: rhythm($full-space);

  @media screen and (min-width: $tablet-breakpoint) {
    margin-top: rhythm($extra-space);
    margin-bottom: rhythm($extra-space);
  }
}
