@import '../../common';

.list {
  padding-left: 0;
  list-style: none;

  // stylelint-disable-next-line selector-no-type
  li {
    margin-bottom: rem-rhythm(0.5);
  }
}
