// Applies vertical spacing to an element.
//
//   $space     - The number of lines to apply to margin-bottom.
//                Defaults to $space.
//   $font-size - The font-size of the element applying spacing to.
//                Defaults to $base-font-size.
//
@mixin spacing($space: $space, $font-size: $base-font-size) {
  margin-top: 0;
  margin-bottom: rhythm($space, $font-size);

  @if $space != 0 {
    &:last-child {
      margin-bottom: 0;
    }

    &.last-child {
      margin-bottom: 0;
    }
  }
}

// Applies horizontal spacing to an element.
//
//   $space     - The number of lines to apply to margin-left.
//                Defaults to $space.
//   $font-size - The font-size of the element applying spacing to.
//                Defaults to $base-font-size.
//
@mixin horizontal-spacing($space: $space, $font-size: $base-font-size) {
  margin-left: 0;
  margin-right: rhythm($space, $font-size);

  @if $space != 0 {
    &:last-child {
      margin-right: 0;
    }

    &.last-child {
      margin-right: 0;
    }
  }
}
