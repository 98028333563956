@import '../common';

.loading-page__row {
  @include row-width;
  @include row-margin;
  padding-top: rem-rhythm(2);
  padding-bottom: rem-rhythm(2);
}

.loading-page__title {
  margin: 0;
  font-size: rem($font-size-md);
  font-family: $meta-normal-font-family;
  font-weight: normal;
  line-height: 1;
}
