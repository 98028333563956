// ----------------------------------------------------------------------------
// di Font Family
// ----------------------------------------------------------------------------
$font-family: Helvetica, Arial, sans-serif;

@font-face {
  font-family: 'Avenir Black';
  src: url('https://di-static-assets.azureedge.net/fonts/avenir-black.woff2') format('woff2'),
    url('https://di-static-assets.azureedge.net/fonts/avenir-black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-E308;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Avenir 85 Heavy';

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

// Use border-box box sizing by default
body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  color: #212529;
  background-color: #fff;

  .disable-scroll {
    overflow: hidden;
  }
}

html,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%; // [2]

  text-size-adjust: 100%; // [3]

  -webkit-font-smoothing: antialiased; // [4]
  -moz-osx-font-smoothing: grayscale; // [4]
  line-height: 1.5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

button:focus {
  outline: 0;
}
button:hover {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Avenir Black', 'Helvetica Bold', Helvetica, Arial, sans-serif;
  font-weight: 900;
}
