@import '../common';

.checkmark-list__item {
  @include spacing(0.5);
  display: flex;
}

.checkmark-list__icon {
  color: $red;
  flex: none;
  margin-right: rem-rhythm(0.5);
}

.checkmark-list__content {
  max-width: 100%;
}
