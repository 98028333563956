@import '../common';

@keyframes slidedown--entered {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  30% {
    opacity: 0.5;
    transform: scaleY(0.5);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes slidedown--exiting {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }

  70% {
    opacity: 0.5;
    transform: scaleY(0.5);
  }

  100% {
    opacity: 0;
    transform: scaleY(0);
  }
}

.slidedown {
  transform-origin: 0 0;
}

.slidedown--entering {
  opacity: 0;
  transform: scaleY(0);
}

.slidedown--entered {
  animation: slidedown--entered 300ms ease-out;
}

.slidedown--exiting {
  animation: slidedown--exiting 300ms ease-out;
}
