@import '../common';

$widget-footer-logo-height: 26px;
$widget-footer-small-legal-font-size: 8px;
$widget-footer-legal-font-size: 11px;

.widget-footer {
  padding-top: rem-rhythm(0.5);
  padding-bottom: rem-rhythm(0.5);
  font-size: rem($widget-footer-legal-font-size);
  color: $muted-color;
  text-align: center;
  background: $background-color;
  border-top: 1px solid $border-color;
}

.widget-footer__byline {
  @extend %caps;
}

.widget-footer__logo {
  margin-left: rem-rhythm(0.5);
  max-height: $widget-footer-logo-height;
  vertical-align: middle;
}

.widget-footer__legal {
  margin: rem-rhythm(0.25) 0 0;
  padding: 0 rem-rhythm(0.25);
  font-size: em($widget-footer-small-legal-font-size, $widget-footer-legal-font-size);
  line-height: em-rhythm(0.5, $widget-footer-small-legal-font-size);
}

@include on-desktop {
  .widget-footer__legal {
    margin-top: rem-rhythm(0.5);
    padding: 0 rem-rhythm(0.5);
    font-size: 1em;
    line-height: em-rhythm(0.7, $widget-footer-legal-font-size);
  }
}
