@import '../common';

.carousel {
  @extend %no-bullets;
  @extend %pie-clearfix;
  position: relative;
  width: 100%;
  overflow: hidden;
}

%absolute-carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

%slide-carousel-item {
  backface-visibility: hidden;
  perspective: 1000px;
}

%active-slide-carousel-item {
  transition: transform 300ms ease-out;
}

.carousel__item {
  overflow-x: auto;
  overflow-y: hidden;
}

.carousel__item--entering {
  @extend %slide-carousel-item;
}

.carousel__item--entered {
  @extend %active-slide-carousel-item;
}

.carousel__item--exiting {
  @extend %slide-carousel-item;
  @extend %absolute-carousel-item;
}

.carousel__item--exited {
  @extend %active-slide-carousel-item;
}

.carousel--enter-right {
  .carousel__item--entering {
    transform: translate3d(100%, 0, 0);
  }

  .carousel__item--entered {
    transform: translate3d(0%, 0, 0);
  }

  .carousel__item--exiting {
    transform: translate3d(0, 0, 0);
  }

  .carousel__item--exited {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel--enter-left {
  .carousel__item--entering {
    transform: translate3d(-100%, 0, 0);
  }

  .carousel__item--entered {
    transform: translate3d(0, 0, 0);
  }

  .carousel__item--exiting {
    transform: translate3d(0, 0, 0);
  }

  .carousel__item--exited {
    transform: translate3d(100%, 0, 0);
  }
}
