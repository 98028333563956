/// Applies vertical spacing to an element.
///
/// @param {Number} $space - The number of lines to apply to margin-bottom. Defaults to $space.
///
@mixin spacing($space: $space) {
  margin-top: 0;
  margin-bottom: rem-rhythm($space);

  &:last-child {
    margin-bottom: 0;
  }
}

/// Applies horizontal spacing to an element.
///
/// @param {Number} $space - The number of lines to apply to margin-right. Defaults to $space.
///
@mixin horizontal-spacing($space: $space) {
  margin-left: 0;
  margin-right: rem-rhythm($space);

  &:last-child {
    margin-right: 0;
  }
}
