@import '../../common';

.provider-widget {
  @include row-width;
  padding: rem-rhythm(0.5);

  &__body {
    display: flex;
    align-items: flex-start;

    @include max-screen($viewport-md) {
      justify-content: space-between;
      flex-direction: row;
    }

    @include max-screen($viewport-sm) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__card {
    width: 33%;
    border: 1px solid $border-color;
    border-bottom: 4px solid $border-color;
    border-radius: 5px;

    @include max-screen($viewport-sm) {
      width: 100%;
      margin-bottom: rem-rhythm(0.5);
    }

    @include min-screen($viewport-sm) {
      margin-left: rem-rhythm(0.5);
    }
  }

  &__card__date {
    padding: rem-rhythm(0.5);
    text-align: center;
  }

  &__card__insurance {
    background-color: $slight-white;
    padding: rem-rhythm(0.5);
    border-top: 1px solid $border-color;
  }

  &__calendar {
    width: 66%;

    @include max-screen($viewport-sm) {
      width: 100%;
    }
  }
}
