// -------------------------------------------------------------------------------------------------
// Typography
// -------------------------------------------------------------------------------------------------

$legacy-base-font-size: $base-font-size;
$base-font-size: 16px;
$legacy-support-for-ie8: false;

$new-base-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
  Cantarell, 'Helvetica Neue', sans-serif;
$new-heading-font-family: MetaWeb, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

// -------------------------------------------------------------------------------------------------
// Colors
// -------------------------------------------------------------------------------------------------

$red: #d22a15;
$red-scale: generate-color-scale($red);
$red-25: map-get($red-scale, 25);
$red-50: map-get($red-scale, 50);
$red-100: map-get($red-scale, 100);
$red-200: map-get($red-scale, 200);
$red-300: map-get($red-scale, 300);
$red-400: map-get($red-scale, 400);
$red-500: map-get($red-scale, 500);
$red-600: map-get($red-scale, 600);
$red-700: map-get($red-scale, 700);
$red-800: map-get($red-scale, 800);
$red-900: map-get($red-scale, 900);

$blue: #2f8aac;
$di-blue: #285ebf;
$blue-scale: generate-color-scale($blue);
$blue-25: map-get($blue-scale, 25);
$blue-50: map-get($blue-scale, 50);
$blue-100: map-get($blue-scale, 100);
$blue-200: map-get($blue-scale, 200);
$blue-300: map-get($blue-scale, 300);
$blue-400: map-get($blue-scale, 400);
$blue-500: map-get($blue-scale, 500);
$blue-600: map-get($blue-scale, 600);
$blue-700: map-get($blue-scale, 700);
$blue-800: map-get($blue-scale, 800);
$blue-900: map-get($blue-scale, 900);

$green: #009e7e;
$green-scale: generate-color-scale($green);
$green-25: map-get($green-scale, 25);
$green-50: map-get($green-scale, 50);
$green-100: map-get($green-scale, 100);
$green-200: map-get($green-scale, 200);
$green-300: map-get($green-scale, 300);
$green-400: map-get($green-scale, 400);
$green-500: map-get($green-scale, 500);
$green-600: map-get($green-scale, 600);
$green-700: map-get($green-scale, 700);
$green-800: map-get($green-scale, 800);
$green-900: map-get($green-scale, 900);

$orange: #d1892a;
$orange-scale: generate-color-scale($orange);
$orange-25: map-get($orange-scale, 25);
$orange-50: map-get($orange-scale, 50);
$orange-100: map-get($orange-scale, 100);
$orange-200: map-get($orange-scale, 200);
$orange-300: map-get($orange-scale, 300);
$orange-400: map-get($orange-scale, 400);
$orange-500: map-get($orange-scale, 500);
$orange-600: map-get($orange-scale, 600);
$orange-700: map-get($orange-scale, 700);
$orange-800: map-get($orange-scale, 800);
$orange-900: map-get($orange-scale, 900);

$charcoal: #8c8980;
$legacy-charcoal: #2e2c26;
$charcoal-scale: generate-color-scale($charcoal);
$charcoal-25: map-get($charcoal-scale, 25);
$charcoal-50: map-get($charcoal-scale, 50);
$charcoal-100: map-get($charcoal-scale, 100);
$charcoal-200: map-get($charcoal-scale, 200);
$charcoal-300: map-get($charcoal-scale, 300);
$charcoal-400: map-get($charcoal-scale, 400);
$charcoal-500: map-get($charcoal-scale, 500);
$charcoal-600: map-get($charcoal-scale, 600);
$charcoal-700: map-get($charcoal-scale, 700);
$charcoal-800: map-get($charcoal-scale, 800);
$charcoal-900: map-get($charcoal-scale, 900);

$gray: #999;
$gray-scale: generate-color-scale($gray);
$gray-25: map-get($gray-scale, 25);
$gray-50: map-get($gray-scale, 50);
$gray-100: map-get($gray-scale, 100);
$gray-200: map-get($gray-scale, 200);
$gray-300: map-get($gray-scale, 300);
$gray-400: map-get($gray-scale, 400);
$gray-500: map-get($gray-scale, 500);
$gray-600: map-get($gray-scale, 600);
$gray-700: map-get($gray-scale, 700);
$gray-800: map-get($gray-scale, 800);
$gray-900: map-get($gray-scale, 900);

$primary-color: $red;
$secondary-color: $blue;
$text-color: $charcoal-800;
$legacy-text-color: #57554f;
$strong-color: $charcoal-900;
$legacy-strong-color: $legacy-charcoal;
$background-color: #fafafa;
$legacy-background-color: #f0efed;
$border-color: $gray-100;
$legacy-border-color: #d8d8d8;
$rule-color: $gray-200;
$muted-color: $gray;
$new-muted-color: #324354;
$success-color: $green;
$warning-color: $orange;
$error-color: $red;

// -------------------------------------------------------------------------------------------------
// Typography
// -------------------------------------------------------------------------------------------------

$font-size-xxs: 11px;
$font-size-xs: 13px;
$font-size-sm: 16px;
$font-size-md: 20px;
$font-size-lg: 26px;
$font-size-xl: 32px;
$font-size-xxl: 42px;
$font-size-scale: (
  xxs: $font-size-xxs,
  xs: $font-size-xs,
  sm: $font-size-sm,
  md: $font-size-md,
  lg: $font-size-lg,
  xl: $font-size-xl,
  xxl: $font-size-xxl,
);

// -------------------------------------------------------------------------------------------------
// Spacing Sizes
// -------------------------------------------------------------------------------------------------

$spacing-none: rem-rhythm(0);
$spacing-xxs: rem-rhythm(0.125);
$spacing-xs: rem-rhythm(0.25);
$spacing-sm: rem-rhythm(0.5);
$spacing-md: rem-rhythm(1);
$spacing-lg: rem-rhythm(2);
$spacing-xl: rem-rhythm(4);
$spacing-scale: (
  none: $spacing-none,
  xxs: $spacing-xxs,
  xs: $spacing-xs,
  sm: $spacing-sm,
  md: $spacing-md,
  lg: $spacing-lg,
  xl: $spacing-xl,
);

// -------------------------------------------------------------------------------------------------
// Viewport Sizes
// -------------------------------------------------------------------------------------------------

$viewport-xxs: 0;
$viewport-xxs-max: 319px;
$viewport-xs: 320px;
$viewport-xs-max: 479px;
$viewport-sm: 480px;
$viewport-sm-max: 599px;
$viewport-md: 600px;
$viewport-md-max: 799px;
$viewport-lg: 800px;
$viewport-lg-max: 1279px;
$viewport-xl: 1280px;
$viewport-xl-max: 1599px;
$viewport-xxl: 1600px;
$viewport-xxl-max: 999999px;
$viewport-scale: (
  xxs: $viewport-xxs,
  xs: $viewport-xs,
  sm: $viewport-sm,
  md: $viewport-md,
  lg: $viewport-lg,
  xl: $viewport-xl,
  xxl: $viewport-xxl,
);

// -------------------------------------------------------------------------------------------------
// Widget Settings
// -------------------------------------------------------------------------------------------------

$shelves-max-width: 1200px;
$shelves-margin: rem-rhythm(1);
