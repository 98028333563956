%inverse {
  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $inverse-text-color;
  }

  a {
    color: $inverse-link-color;

    &:focus,
    &:hover {
      color: $inverse-text-color;
    }
  }
}
