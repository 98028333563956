@import '../common';

.panel-rule {
  color: $border-color;
  background: transparent;
  border: 0;
  border-bottom: 1px solid;
  height: 0;
  margin: rem-rhythm(0.5) rem-rhythm(-0.5);
}

.panel-rule--large {
  margin: rem-rhythm(1) rem-rhythm(-1);
}
