@import '../../common';

.provider-card-openings {
  font-size: rem($font-size-xs);
  line-height: rem-rhythm(1);
  text-align: center;
}

.provider-card-openings__availibility {
  padding: rem-rhythm(0.25);

  a {
    font-weight: bold;
  }
}

.provider-card-openings__next-opening {
  border-top: 1px solid $border-color;
  padding: rem-rhythm(0.25);
  font-weight: bold;
  font-family: $meta-bold-font-family;

  a {
    color: $red;
  }
}
