@import '../../common';

.appointment-form-page__title {
  small {
    color: $muted-color;
    font-weight: normal;
    font-family: $meta-normal-font-family;
  }

  strong {
    color: $error-color;
  }
}

.appointment-form-page__map {
  display: block;
  border-color: $border-color;
  border-width: 0 1px;
  border-style: solid;
}

.appointment-form-page__row {
  @include row-width;
  @include row-margin(rem-rhythm(0.5));
  padding-bottom: rem-rhythm(0.5);
}

.appointment-form-page__aside {
  display: none;
}

@include min-screen($viewport-md) {
  .appointment-form-page__row {
    @include row-margin;
    display: flex;
    padding-bottom: rem-rhythm(1);
  }

  .appointment-form-page__main {
    flex: 0 0 66.667%;
    max-width: 66.667%;
  }

  .appointment-form-page__aside {
    display: block;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding-left: rem-rhythm(1);
  }
}

.appointment-form-page__links {
  margin-bottom: 1.2em;

  > a {
    color: $error-color;
    border-right: 2px solid $border-color;
    padding-right: rem-rhythm(0.5);
    padding-left: rem-rhythm(0.5);
  }

  > a:last-child {
    border: 0;
  }

  > a:first-child {
    padding-left: 0;
  }
}
