@import '../../common';

.service-list li {
  display: inline-block;
}

.service-list li:not(:first-child) {
  margin-left: rem-rhythm(0.75);

  &::before {
    content: '|';
    color: $gray-500;
    margin-right: rem-rhythm(0.75);
  }
}
