// stylelint-disable max-nesting-depth

@import '../../common';

$main-header-logo-small-width: 109px;
$main-header-logo-large-width: 140px;
$main-header-toggle-size: 20px;
$main-header-item-small-font-size: 13px;
$main-header-item-large-font-size: 16px;
$main-header-item-line-height: 26px;
$main-header-dropdown-font-size: 13px;
$main-header-dropdown-triangle-size: 6px;
$maerkting-header-hover-background-color: #f2f2f2;

.main-header {
  display: flex;
  align-items: center;
  font-size: 1rem;
  flex-wrap: wrap;
  width: 100%;
  min-height: 50px;
  box-sizing: border-box;
  padding: 0 em-rhythm(0.25);
  background: $white;
  position: relative;

  @include on-desktop {
    padding: 0 em-rhythm(0.5);
  }
}

.main-header__mobile-nav {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  @include on-desktop {
    display: none;
  }
}

.main-header__nav {
  display: none;

  @include on-desktop {
    display: flex;
    flex: 1;
  }
}

.main-header__items {
  margin: 0;
  padding: 0;

  @include on-desktop {
    display: flex;
    align-items: center;
  }
}

.main-header__items--main {
  flex: 1;
}

// ----------------------------------------------------------------------------
// Item
// ----------------------------------------------------------------------------

.main-header__item {
  display: block;
  font-family: $meta-normal-font-family;
  font-size: em($main-header-item-small-font-size);
  line-height: em($main-header-item-line-height, $main-header-item-small-font-size);
  background: transparent;
  color: $black;
  border: 0;
  white-space: nowrap;
  transition: all 200ms ease-out;

  @include on-tablet-down {
    font-size: em($main-header-item-small-font-size);
    line-height: em($main-header-item-line-height, $main-header-item-small-font-size);
    padding: em-rhythm(0.625, $main-header-item-small-font-size)
      em-rhythm(0.25, $main-header-item-small-font-size);

    .main-header__dropdown & {
      font-size: em($main-header-item-large-font-size);
      line-height: em($main-header-item-line-height, $main-header-item-large-font-size);
      padding: em-rhythm(0.25, $main-header-item-large-font-size)
        em-rhythm(1, $main-header-item-large-font-size)
        em-rhythm(0.25, $main-header-item-large-font-size)
        em-rhythm(0.5, $main-header-item-large-font-size);

      &:hover {
        padding-left: em-rhythm(0.75, $main-header-item-large-font-size);
        padding-right: em-rhythm(0.75, $main-header-item-large-font-size);
      }
    }
  }

  @include on-desktop {
    font-size: em($main-header-item-large-font-size);
    line-height: em($main-header-item-line-height, $main-header-item-large-font-size);
    padding: em-rhythm(0.675, $main-header-item-large-font-size)
      em-rhythm(0.5, $main-header-item-large-font-size);

    .main-header__dropdown & {
      font-size: em($main-header-dropdown-font-size);
      padding: em-rhythm(0.25, $main-header-dropdown-font-size)
        em-rhythm(1, $main-header-dropdown-font-size)
        em-rhythm(0.25, $main-header-dropdown-font-size)
        em-rhythm(0.5, $main-header-dropdown-font-size);

      &:hover {
        padding-left: em-rhythm(0.75, $main-header-dropdown-font-size);
        padding-right: em-rhythm(0.75, $main-header-dropdown-font-size);
      }
    }
  }

  &:hover {
    background: $maerkting-header-hover-background-color;
    color: $blue;
  }

  &[aria-haspopup='true'] {
    &:hover {
      background: transparent;
      color: $muted-color;
    }
  }

  &[aria-expanded='true'] {
    color: $muted-color;

    .main-header__caret {
      transform: rotate(180deg);
    }
  }
}

.main-header__caret {
  opacity: 0.5;
  font-size: 0.8em;
  transition: transform 150ms ease-out;

  [aria-expanded='true'] & {
    transform: rotate(180deg);
  }
}

.main-header__menu-header {
  @extend %caps;
  @include adjust-font-size-to($main-header-item-small-font-size);
  font-family: $meta-normal-font-family;
  margin: 0;
  padding: em-rhythm(0.25, $main-header-item-small-font-size)
    em-rhythm(0.5, $main-header-item-small-font-size);
  color: $muted-color;
}

.main-header__divider {
  height: 1px;
  overflow: hidden;
  margin: em-rhythm(0.25) 0;
  background: $border-color;
}

// ----------------------------------------------------------------------------
// Dropdown
// ----------------------------------------------------------------------------

.main-header__dropdown-container {
  position: relative;

  .main-header__mobile-nav & {
    position: static;
  }
}

.main-header__dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: em-rhythm(-0.25);
  min-width: em-rhythm(5);
  background: $white;
  border-radius: $border-radius;
  z-index: 1000;
  padding: em-rhythm(0.25) 0;
  box-shadow: rgba(0, 0, 0, 0.19) 0 10px 30px 0, rgba(0, 0, 0, 0.23) 0 6px 10px 0;
  border: 1px solid $border-color;
  transition: opacity 100ms ease-out, transform 200ms ease-out;

  &::before,
  &::after {
    width: 0;
    height: 0;
    border-width: $main-header-dropdown-triangle-size;
    border-style: solid;
    content: '';
    border-color: transparent transparent currentColor;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -$main-header-dropdown-triangle-size;
  }

  &::before {
    color: $border-color;
    z-index: $dropdown-z-index - 1;
  }

  &::after {
    color: $white;
    z-index: $dropdown-z-index + 1;
    margin-bottom: -1px;
  }

  &[aria-hidden='true'] {
    pointer-events: none;
    opacity: 0;
    transform: translateY(em-rhythm(-0.25));
  }

  &[aria-hidden='false'] {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0);
  }

  .main-header__items--main & {
    left: 0;
    right: auto;
  }

  .main-header__mobile-nav & {
    width: 100%;

    &::before,
    &::after {
      left: auto;
      right: em-rhythm(0.5);
      margin-left: 0;
      margin-right: $main-header-dropdown-triangle-size / 2;
    }
  }
}

// ----------------------------------------------------------------------------
// Logo
// ----------------------------------------------------------------------------

.main-header__logo {
  flex: 0 0 $main-header-logo-small-width;
  margin: 0 em-rhythm(0.5);

  img {
    display: block;
  }

  // stylelint-disable-next-line order/order
  @include on-desktop {
    flex: 0 0 $main-header-logo-large-width;
  }
}

// ----------------------------------------------------------------------------
// Toggle
// ----------------------------------------------------------------------------

.main-header__toggle {
  background: transparent;
  border: 0;
  box-sizing: content-box;
  width: $main-header-toggle-size;
  height: $main-header-toggle-size;
  position: relative;
}

.main-header__toggle-line {
  width: $main-header-toggle-size;
  height: 2px;
  overflow: hidden;
  display: block;
  background: $strong-color;
  transition: all 200ms ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: $main-header-toggle-size / -2;
}

.main-header__toggle-line--top {
  transform: translateY(-7px);

  [aria-expanded='true'] & {
    transform: rotate(135deg);
  }
}

.main-header__toggle-line--middle {
  [aria-expanded='true'] & {
    opacity: 0;
    transform: rotate(135deg);
  }
}

.main-header__toggle-line--bottom {
  transition-delay: 50ms;
  transform: translateY(7px);

  [aria-expanded='true'] & {
    transform: rotate(45deg);
  }
}
