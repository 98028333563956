@import '../../common';

$office-review-picture-size-small: 20px;
$office-review-picture-size-large: 40px;

.office-review {
  background: $white;

  @include on-tablet-up {
    border: 1px solid $border-color;
    border-bottom-width: 4px;
    border-radius: $border-radius;
  }
}

.office-review__header {
  @include flex;

  align-items: top;
  padding: rem-rhythm(0.5);

  @include on-tablet-up {
    background: $slight-white;
    border-bottom: 1px solid $border-color;
  }
}

.office-review__picture {
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  overflow: hidden;
  font-family: $meta-normal-font-family;
  font-size: em($office-review-picture-size-small * 0.5);
  line-height: 2em;
  text-align: center;
  background: $border-color;
  border-radius: 100%;

  @include on-tablet-up {
    font-size: em($office-review-picture-size-large * 0.5);
  }
}

.office-review__header-content {
  @include flex;

  align-items: flex-start;
  flex: 1;
  padding: 0 rem-rhythm(0.33);

  @include on-tablet-up {
    display: block;
  }
}

.office-review__reviewer-name {
  margin: em(1px) em-rhythm(0.25, $base-font-size) 0 0;
  font-family: $meta-bold-font-family;
  font-size: em($base-font-size);
  line-height: em($office-review-picture-size-small, $base-font-size);
}

.office-review__star-rating {
  display: block;
  line-height: 1em;
}

.office-review__service-logo {
  flex-shrink: 0;
}

.office-review__message {
  padding: rem-rhythm(0.5);
  margin-top: rem-rhythm(-0.8);
  font-size: em($small-font-size);
  line-height: em-rhythm($small-line-height, $small-font-size);

  @include on-tablet-up {
    margin-top: 0;
  }
}
