@import '../common';

.not-found-page__row {
  @include row-width(600px);
  @include row-margin;
  padding-top: rem-rhythm(2);
  padding-bottom: rem-rhythm(2);
  text-align: center;
}

.not-found-page__title {
  margin: 0;
  font-size: rem($font-size-lg);
  font-family: $meta-normal-font-family;
  font-weight: normal;
  line-height: 1.5;
}
