@import '../common';

.checkbox {
  display: flex;
  align-items: baseline;
}

.checkbox__input {
  flex: none;
  vertical-align: middle;
}

.checkbox__label {
  max-width: 100%;
  padding-left: rem-rhythm(0.5);
}
