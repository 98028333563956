@import './common';

.opening {
  @extend %ellipsis;
  display: block;
  width: 100%;
  padding: 0 rem-rhythm(0.125);
  font-size: em($calendar-opening-font-size);
  line-height: em-rhythm($calendar-opening-line-height, $calendar-opening-font-size);
  transition: none;
  border: 0;
  background: transparent;
  color: $link-color;

  @include on-mobile {
    font-size: em($calendar-table-mobile-opening-font-size);
    line-height: em-rhythm($calendar-opening-line-height, $calendar-table-mobile-opening-font-size);
  }

  tr:first-child & {
    margin-top: 0;
    border-top: 0;
  }
}

.opening:focus,
.opening:hover,
.opening--active {
  position: relative;
  z-index: 50;
  margin-top: -1px;
  margin-bottom: -1px;
  // !important is to override the default styles of the Link component, which
  // is handled by emotion. Once this component is rebuilt using emotion,
  // this will probably no longer be necessary
  color: $primary-button-text-color !important;
  background: $primary-button-background-color;
  border-top: 1px solid $primary-button-background-color;
  border-bottom: 1px solid $primary-button-background-color;
}
