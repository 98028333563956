@import '../common';

$review-service-logo-size: 20px;
$review-service-logo-font-size: $review-service-logo-size * 0.6;

.review-service-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: em($review-service-logo-size, $review-service-logo-font-size);
  height: em($review-service-logo-size, $review-service-logo-font-size);
  font-size: em($review-service-logo-font-size);
  color: $white;
  vertical-align: middle;
  background-color: $muted-color;
  border: 1px solid $muted-color;
  border-radius: 100%;
}
