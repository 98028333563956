@import '../../common';

.cancel-appointment-page__row {
  @include row-width;
  padding: rem-rhythm(0.5);
}

.cancel-appointment-page__aside {
  margin-top: rem-rhythm(0.5);
}

.cancel-appointment-page__map {
  display: block;
  border: 1px solid $border-color;
}

@include min-screen($viewport-lg) {
  .cancel-appointment-page__row {
    display: flex;
  }

  .cancel-appointment-page__main {
    flex: 0 0 66.667%;
    max-width: 66.667%;
  }

  .cancel-appointment-page__aside {
    display: block;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-top: 0;
    padding-left: rem-rhythm(0.5);
  }
}
