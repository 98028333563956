// stylelint-disable max-nesting-depth

@import '../../common';
@import '../../../shared//components/LeafletMap/LeafletMap.scss';

.practice-page__content {
  position: relative;
  z-index: 2;

  @include min-screen($viewport-lg) {
    padding: rem-rhythm(0.5);
    max-width: 400px;
  }
}

.practice-page__box {
  @include min-screen($viewport-lg) {
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
}

.practice-header {
  display: flex;
  align-items: center;
  padding: rem-rhythm(0.75) rem-rhythm(0.5);
  background-color: $blue;

  @include max-screen($viewport-md) {
    padding: rem-rhythm(0.5);
  }

  h3 {
    color: $white;
    font-family: $meta-normal-font-family;
    font-size: rem($h4-font-size);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 0 0 0 rem-rhythm(0.5);

    @include max-screen($viewport-md) {
      font-size: rem($h5-font-size);
    }
  }
}

.practice-list {
  margin: 0;
  padding: 0;
}

.practice-list__item {
  width: 100%;
  list-style-type: none;
  border-top: 1px solid $gray-100;
  transition: all 0.4s;

  h2 {
    line-height: 1.25em;
  }

  > div {
    padding: rem-rhythm(0.5);
  }

  &:first-child {
    border-top: 0;
  }

  &__anchor {
    display: flex;
    align-items: center;
    padding: rem-rhythm(0.5);

    :focus {
      border-color: red;
    }
  }

  &__body {
    flex: 1;
    margin: 0 rem-rhythm(0.5);

    > p {
      color: $text-color;
      // font-family: $meta-normal-font-family;
      font-size: rem($base-font-size);
      margin: 0;
    }
  }

  &__icon {
    width: 1.5em;
    height: 1.5em;
    transition: all 0.4s;
    color: $gray-300;

    &--active {
      transform: scale(1.2) translateX(5px);
      color: $gray-600;
    }
  }

  &__title {
    color: $blue;
    font-family: $meta-bold-font-family;
    font-size: rem($h3-font-size);
    margin: 0;
    transition: all 0.4s;

    &--active {
      color: $red;
    }
  }

  &--active {
    background-color: $gray-50;
  }
}
