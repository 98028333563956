@import '../common';

.widget-form {
  background: $slight-white;
  border-bottom: 1px solid $border-color;
}

.widget-form-row {
  @include row-width;
  padding: rem-rhythm(0.5);
}

@include min-screen($viewport-md) {
  .widget-form-row {
    display: flex;
  }

  .widget-form__field {
    flex: 1;
    margin-bottom: 0;

    &:first-child {
      padding-right: rem-rhythm(0.5);
    }
  }
}
