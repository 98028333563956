@import './common';

.calendar-table-row {
  th,
  td {
    border-top: 1px solid $table-hover-background-color;
  }
}

.calendar-table-row:hover {
  th,
  td {
    background-color: $table-hover-background-color;
  }
}

.calendar-table-row--hour-start {
  th,
  td {
    border-top: 1px solid $border-color;
  }
}

.calendar-table-row__time {
  @extend %muted;
  @include adjust-font-size-to($calendar-time-font-size, $calendar-opening-line-height);
  padding-right: rhythm(0.25, $calendar-time-font-size);
  padding-left: rhythm(0.25, $calendar-time-font-size);
  text-align: left;
  vertical-align: top;
}
