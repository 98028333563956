@import '../../common';

.reschedule-form__provider-header {
  @extend %strong;
  @include adjust-font-size-to($h3-font-size, $h3-line-height);
  font-family: $meta-bold-font-family;
  margin: 0 0 rem-rhythm(1 / 2);
}

.reschedule-form__provider-photo {
  vertical-align: middle;
  margin-right: rem-rhythm(1 / 2);
}

.reschedule-form__dl {
  dt {
    @include adjust-font-size-to($font-size-xs, 1);
    color: $new-muted-color;
  }

  dd {
    margin: 0 0 rem-rhythm(1 / 2);
  }
}

.reschedule-form__old {
  @extend %error-highlight;
  text-decoration: line-through;
}

.reschedule-form__new {
  @extend %success-highlight;
}

.reschedule-form__calendar {
  margin-bottom: rem-rhythm(1);
}

@include min-screen($viewport-md) {
  .reschedule-form__row {
    display: flex;
  }

  .reschedule-form__info {
    display: block;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    order: 1;
  }

  .reschedule-form__calendar {
    flex: 0 0 66.667%;
    max-width: 66.667%;
    margin: 0;
    padding-left: rem-rhythm(1);
    order: 2;
  }
}
