@import './common';

// -------------------------------------------------------------------------------------------------
// Calendar Table
// -------------------------------------------------------------------------------------------------

.calendar-table {
  overflow-x: auto;
  overflow-y: hidden;
  border: 0;
  border-radius: 0;
  background: $white;
  width: 100%;
}

.calendar-table__colgroup {
  width: $calendar-nav-width;
  background: transparent;
}

.calendar-table__colgroup--hovered {
  background: $table-hover-background-color;
}

// -------------------------------------------------------------------------------------------------
// Calendar Table > Message
// -------------------------------------------------------------------------------------------------

.calendar-table__message {
  padding: rem-rhythm(0.5);
  background: $calendar-message-background;
  text-align: center;

  &:empty {
    display: none;
  }
}

.calendar-table__message--large {
  padding: rem-rhythm(1);
}

// -------------------------------------------------------------------------------------------------
// Calendar Table > Header Cell
// -------------------------------------------------------------------------------------------------

.calendar-table__header-cell {
  @include gradient-background($data-table-head-background-color);
  font-size: em($font-size-xs);
  padding-top: em-rhythm(0.25, $font-size-xs);
  padding-bottom: em-rhythm(0.25, $font-size-xs);
  line-height: em-rhythm(0.75, $font-size-xs);
  text-align: center;
  border-bottom: 1px solid $data-table-head-border-color;
  color: $text-color;
}

.calendar-table__header-cell--starts-week {
  border-left: 1px dashed $muted-color;
}

.calendar-table__header-cell-date {
  font-weight: normal;
  white-space: nowrap;

  @include on-mobile {
    font-size: em($calendar-table-mobile-header-date-font-size, $font-size-xs);
    line-height: em-rhythm(0.75, $calendar-table-mobile-header-date-font-size);
  }
}
