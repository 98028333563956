// Creates a subtle top to bottom gradient of the given color.
//
//   $color        - The middle value of the gradient range. This is also
//                   The fallback color for older browsers.
//   $top-color    - The top color of the gradient.
//                   Defaults to 3% lighter than the middle $color.
//   $bottom-color - The bottom color of the gradient.
//                   Defaults to 3% darker than the middle $color.
//
@mixin gradient-background($color, $top-color: null, $bottom-color: null) {
  $top-color: lighten($color, 3%) !default;
  $bottom-color: darken($color, 3%) !default;

  background-color: $color;
  background-image: linear-gradient(to bottom, $top-color, $bottom-color);
}
