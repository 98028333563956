// stylelint-disable max-nesting-depth

@import '../common';

:global {
  @each $key, $value in $font-size-scale {
    .font-size-#{$key} {
      font-size: em($value);
    }
  }

  .font-family-base {
    font-family: $base-font-family;
  }

  .font-family-meta-normal {
    font-family: $meta-normal-font-family;
  }

  .font-family-meta-bold {
    font-family: $meta-bold-font-family;
  }

  .font-weight-normal {
    font-weight: normal;
  }

  .font-weight-bold {
    font-weight: bold;
  }

  .line-height-solid {
    line-height: 1em;
  }

  .line-height-title {
    line-height: 1.25em;
  }

  .line-height-copy {
    line-height: 1.5em;
  }

  .text-ellipsis {
    @include ellipsis;
  }

  .text-nowrap {
    white-space: nowrap;
  }

  .text-align-left {
    text-align: left;
  }

  .text-align-center {
    text-align: center;
  }

  .text-align-right {
    text-align: right;
  }
}
