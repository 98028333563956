@import '../common';

// Using 16px for the font size prevents zooming in effect on mobile devices:
// http://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
$input-font-size: 16px;

@mixin input-border-color($border-color) {
  border-color: $border-color;

  &:hover {
    border-color: darken($border-color, 15%);

    &:disabled {
      border-color: $border-color;
    }
  }

  &:focus {
    border-color: $blue;
  }
}

.input {
  @include adjust-font-size-to($input-font-size, $input-line-height);
}

.input--text {
  @include input-border-color($input-border-color);
  width: 100%;
  padding: rem-rhythm(0.25) rem-rhythm(0.375);
  font-size: rem($input-font-size);
  line-height: rem-rhythm(0.75);
  min-height: calc(#{rem-rhythm(1.25)} + 2px);
  color: $input-text-color;
  background: $input-background-color;
  border-style: solid;
  border-width: 1px;
  border-radius: $input-border-radius;
  outline: none;
  box-shadow: inset 0 1px 2px $input-box-shadow-color;
  transition-timing-function: ease-out;
  transition-duration: $out-duration;
  transition-property: border-color, box-shadow;

  &:focus {
    transition-duration: $in-duration;
  }

  &:disabled {
    color: $input-disabled-text-color;
    cursor: default;
    cursor: not-allowed;
    background: $input-disabled-background-color;
    box-shadow: none;
    // Fixes some mobile Webkit issues.
    -webkit-text-fill-color: $input-disabled-text-color;
    opacity: 1;
  }
}

.input--multiline {
  height: auto;
}

.input--text.input--gray {
  background: $input-gray-background-color;

  &:disabled {
    background: $input-gray-disabled-background-color;
  }
}

.input--success {
  @include input-border-color($success-color);
}

.input--error {
  @include input-border-color($error-color);
}
