// stylelint-disable max-nesting-depth

@import '../../common';

$footer-background-color: $inverse-background-color;
$footer-color: $rule-color;

.main-footer {
  @extend %inverse;
  color: $footer-color;
  background: $footer-background-color;
  font-family: $meta-normal-font-family;
}

.main-footer__nav {
  padding: rem-rhythm(1) 0;
}

.main-footer__header {
  @extend %caps;
  @include adjust-font-size-to($h6-font-size, $h6-line-height);
  margin: 0 0 rem-rhythm(0.5);
  font-family: $meta-bold-font-family;

  .main-footer & {
    color: $footer-color;
  }
}

.main-footer__row {
  @include row-width;
  @include row-margin;
}

.main-footer__row--with-columns {
  @include min-screen($viewport-sm) {
    display: flex;
    flex-wrap: wrap;
  }
}

.main-footer__column {
  margin-bottom: rem-rhythm(1);

  @include min-screen($viewport-sm) {
    flex: 0 0 50%;
    max-width: 50%;

    &:nth-child(even) {
      padding-left: rem-rhythm(1);
    }
  }

  @include min-screen($viewport-lg) {
    flex: 0 0 16.667%;
    max-width: 16.667%;
    padding-left: rem-rhythm(1);

    &:first-child {
      padding-left: 0;
    }
  }
}

.main-footer__items {
  @extend %no-bullets;
  margin: 0;

  @include max-screen($viewport-xs-max) {
    li {
      display: inline-block;

      &::after {
        @include adjust-font-size-to($small-font-size);
        margin: 0 em-rhythm(0.25, $small-font-size);
        color: $muted-color;
        content: '|';
      }

      &:last-child {
        &::after {
          content: '';
          display: none;
        }
      }
    }
  }
}

.main-footer__extra {
  padding: rem-rhythm(0.5) 0;
  background: darken($footer-background-color, 5%);
}

.main-footer__extra-items {
  @extend %horizontal-spaced-list;
  @extend %small;
  @extend %text-center;
  margin: 0;
}

.main-footer__copyright {
  @include on-tablet-down {
    display: block;
  }
}
