/// Will output a rem value from the given px value based on the `$base-font-size`.
///
/// @param {Number} $value - The new font-size.
/// @return {Number}
///
/// @example scss
///   font-size: rem(20px);
///   // font-size: 1.25rem;
@function rem($value) {
  @return 1rem * ($value / $base-font-size);
}

/// Will output a rem value from the given fraction of the `$base-line-height`.
///
/// @param {Number} $value - Fraction of the line-height.
/// @return {Number}
///
/// @example scss
///   margin-right: rem-rhythm(0.5);
///   // margin-right: 0.75rem;
@function rem-rhythm($value) {
  @return rem($value * $base-line-height);
}

/// Will output an em value from the given px value based on the `$base-font-size`. This is
/// essentially the same function used in compass's `adjust-font-size-to` mixin.
///
/// @param {Number} $value - The new font size.
/// @param {Number} $font-size [$base-font-size] - Parent font-size.
/// @return {Number}
///
/// @example scss
///   font-size: em(20px);
///   // font-size: 1.25em;
///   font-size: em(12px, $from-size: 20px)
///   // font-size: 0.6em
@function em($value, $font-size: $base-font-size) {
  @return 1em * ($value / $font-size);
}

/// Will output an em value from the given fraction of the `$base-line-height`. This is essentially
/// the same as compass's `rhythm` function.
///
/// @param {Number} $value - Fraction of the line height.
/// @param {Number} $font-size [$base-font-size] - Current font-size.
/// @param {Number} $offset [0]
/// @return {Number}
///
/// @example scss
///   margin-right: em-rhythm(0.5);
///   // margin-right: 0.75em;
///   margin-right: em-rhythm(0.5, $font-size: 20px);
///   // margin-right: 0.6em;
@function em-rhythm($value, $font-size: $base-font-size, $offset: 0) {
  @return 1em * ($value * $base-line-height - $offset) / $font-size;
}

/// Will output a rem value that is visually consistent with the original rem value used in the
/// legacy system.
///
/// @param {Number} $value - The original rem value
/// @return {Number}
///
/// @example scss
///   font-size: legacy-rem(0.5);
///   // font-size: 0.46875rem;
@function legacy-rem($value) {
  @return $value * ($legacy-base-font-size / $base-font-size) * 1rem;
}
