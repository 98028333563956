%no-bullets {
  padding-left: 0;
  list-style: none;
}

// ----------------------------------------------------------------------------
// Horizontal Lists
// ----------------------------------------------------------------------------

%horizontal-list {
  @extend %no-bullets;

  li {
    @extend %inline-block;
    margin-left: -4px;

    &:first-child {
      margin-left: 0;
    }
  }
}

%horizontal-spaced-list {
  @extend %horizontal-list;

  li {
    @include horizontal-spacing;
  }
}

%horizontal-divided-list {
  @extend %horizontal-spaced-list;

  @include with-feature(generatedcontent) {
    li {
      margin: 0;

      &:after {
        @include adjust-font-size-to($small-font-size, 1);
        margin: 0 rhythm(0.25, $small-font-size);
        color: $muted-color;
        content: '|';
      }

      &:last-child {
        &:after {
          content: '';
          display: none;
        }
      }

      &.last-child {
        &:after {
          content: '';
          display: none;
        }
      }
    }
  }
}

// ----------------------------------------------------------------------------
// Divided Lists
// ----------------------------------------------------------------------------

%divided-list {
  @extend %no-bullets;

  > li {
    margin-top: rhythm($full-space);
    padding-top: rhythm($full-space);
    border-top: 1px solid $border-color;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }
  }
}

%small-divided-list {
  > li {
    margin-top: rhythm($space);
    padding-top: rhythm($space);
  }
}

%box-divided-list {
  > li {
    margin-left: rhythm(-$full-space);
    margin-right: rhythm(-$full-space);
    padding-left: rhythm($full-space);
    padding-right: rhythm($full-space);
  }
}

%small-box-divided-list {
  > li {
    margin-left: rhythm(-$space);
    margin-right: rhythm(-$space);
    padding-left: rhythm($space);
    padding-right: rhythm($space);
  }
}

// ----------------------------------------------------------------------------
// Definition Lists
// ----------------------------------------------------------------------------

%inline-dl {
  dt,
  dd {
    @extend %inline-block;
    margin-bottom: 0;
    vertical-align: baseline;
  }

  dt {
    margin-left: rhythm($full-space);

    &:first-child {
      margin-left: 0;
    }
  }
}

%icon-dl {
  @include pie-clearfix;

  dt {
    float: left;
    clear: both;
    width: rhythm($full-space, $small-font-size);
    color: $strong-color;
    text-align: center;
  }

  dd {
    @extend %no-space-bottom;
    margin-left: rhythm(1.25);
  }
}

%inline-icon-dl {
  dt {
    float: none;
  }

  dd {
    margin-left: 0;
  }
}
