@import '../../common';

// This is necessary to adjust the SVG icon to visually match the old font-based icon position
$back-button-icon-horizontal-adjustment: 3.05px;
$back-button-icon-vertical-adjustment: 3.5px;

.back-button {
  display: block;
  border: 0;
  padding: legacy-rem(1) legacy-rem(0.5) - rem($back-button-icon-horizontal-adjustment);
  font-size: legacy-rem(1.3);
  line-height: legacy-rem(1.5) - rem($back-button-icon-vertical-adjustment);
  color: $white;
  background: $blue-desaturated;

  &:focus,
  &:hover {
    color: $white;
    background: $blue-shadow;
  }

  // stylelint-disable-next-line order/order
  @include min-screen($s-screen) {
    padding-right: legacy-rem(1) - rem($back-button-icon-horizontal-adjustment);
    padding-left: legacy-rem(1) - rem($back-button-icon-horizontal-adjustment);
  }
}
