@import '../../common';

.appointment-form-page__row {
  @include row-width;
}

.appointment-form-page__aside {
  display: none;
}

@include max-screen($viewport-lg) {
  .appointment-form-page__main {
    border: 0;
  }
}

@include min-screen($viewport-lg) {
  .appointment-form-page__row {
    display: flex;
    padding: rem-rhythm(0.5);
  }

  .appointment-form-page__main {
    flex: 0 0 66.667%;
    max-width: 66.667%;
    margin-bottom: 0;
  }

  .appointment-form-page__aside {
    display: block;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding-left: rem-rhythm(0.5);
  }
}
