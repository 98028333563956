/// Setup flexbox on the element.
///
/// @output Essentially sets `display: flex`, but with some partial support for
///         IE 9 using `display: table`.
/// @example scss
///   .flex {
///     @include flex;
///   }
@mixin flex {
  // For partial IE 9 support
  display: table;
  display: flex;
  width: 100%;

  @include on-ie-9 {
    > * {
      display: table-cell;
      vertical-align: top;
    }
  }
}
