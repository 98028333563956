// stylelint-disable max-nesting-depth

@import '../common';

:global {
  @each $key, $value in $spacing-scale {
    .margin-#{$key} {
      margin: $value;
    }

    .margin-top-#{$key} {
      margin-top: $value;
    }

    .margin-right-#{$key} {
      margin-right: $value;
    }

    .margin-bottom-#{$key} {
      margin-bottom: $value;
    }

    .margin-left-#{$key} {
      margin-left: $value;
    }

    .margin-horizontal-#{$key} {
      margin-left: $value;
      margin-right: $value;
    }

    .margin-vertical-#{$key} {
      margin-top: $value;
      margin-bottom: $value;
    }

    .padding-#{$key} {
      padding: $value;
    }

    .padding-top-#{$key} {
      padding-top: $value;
    }

    .padding-right-#{$key} {
      padding-right: $value;
    }

    .padding-bottom-#{$key} {
      padding-bottom: $value;
    }

    .padding-left-#{$key} {
      padding-left: $value;
    }

    .padding-horizontal-#{$key} {
      padding-left: $value;
      padding-right: $value;
    }

    .padding-vertical-#{$key} {
      padding-top: $value;
      padding-bottom: $value;
    }
  }
}
