@import '../common';

.force-selection {
  position: relative;
  min-height: rhythm(9);
}

.force-selection--with-additional-info {
  min-height: rhythm(20);
}

.force-selection--selected {
  min-height: 0;
}

.force-selection__message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: rem-rhythm(0.5);
  background: rgba($white, 0.95);
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.force-selection__additional-info {
  max-width: 40em;
  margin: 0 auto;
}
