// Truncates text and adds an ellipsis to represent overflow.
//
//   $no-wrap - Include white-space: nowrap
@mixin ellipsis($no-wrap: true) {
  @if $no-wrap {
    white-space: nowrap;
  }
  overflow: hidden;
  text-overflow: ellipsis;
}
