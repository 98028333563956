@import '../common';

@keyframes scaleup--entered {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }

  70% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleup--exiting {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  30% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

.scaleup {
  width: 100%;
}

.scaleup--entering {
  transform: scale(0.8);
  opacity: 0;
}

.scaleup--entered {
  animation: scaleup--entered 300ms ease-out;
}

.scaleup--exiting {
  animation: scaleup--exiting 300ms ease-out;
}
