@import '../common';

$rating-stats-logo-font-size: 16px;
$rating-stats-value-font-size: 16px;
$rating-stats-line-height: 16px;
$rating-stats-count-font-size: 11px;
$rating-stats-star-font-size: 14px;
$rating-stats-star-line-height: 12px;
$rating-stats-height: em-rhythm(1);

.rating-stats {
  @include flex;

  align-items: center;
  height: $rating-stats-height;
  box-sizing: content-box;
  padding: 0;
}

.rating-stats__logo {
  width: em($base-line-height, $rating-stats-logo-font-size);
  height: 100%;
  padding: 0 em(1px, $rating-stats-logo-font-size);
  font-size: em($rating-stats-logo-font-size);
  border-right: 0;
  border-radius: $button-border-radius 0 0 $button-border-radius;
}

.rating-stats__details {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 em(6px);
  border: 1px solid $border-color;
  border-left: 0;
  border-top-right-radius: $button-border-radius;
  border-bottom-right-radius: $button-border-radius;
}

.rating-stats__value {
  font-size: em($rating-stats-value-font-size);
  line-height: em($rating-stats-line-height, $rating-stats-value-font-size);
}

.rating-stats__star-icon {
  margin-left: em-rhythm(0.125, $rating-stats-star-font-size);
  font-size: em($rating-stats-star-font-size);
  line-height: em($rating-stats-star-line-height, $rating-stats-star-font-size);
  color: $star-rating-color;

  @include on-desktop {
    display: none;
  }
}

.rating-stats__star-rating {
  margin-left: em-rhythm(0.125, $rating-stats-star-font-size);
  font-size: em($rating-stats-star-font-size);
  line-height: em($rating-stats-star-line-height, $rating-stats-star-font-size);

  @include on-tablet-down {
    display: none;
  }
}

.rating-stats__count {
  margin-left: em-rhythm(0.25, $rating-stats-count-font-size);
  font-size: em($rating-stats-count-font-size);
  line-height: em($rating-stats-line-height, $rating-stats-count-font-size);
  white-space: nowrap;

  @include on-mobile {
    display: none;
  }
}
