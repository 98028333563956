@import '../../common';

@keyframes slideup {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.office-provider-list-alert {
  @include row-width;
  padding: rem-rhythm(0.5);
}

.office-provider-list {
  @include row-width;
  display: flex;
  flex-wrap: wrap;
  padding: rem-rhythm(0.25);
}

.office-provider-list__item {
  animation: slideup 100ms ease-out;
  animation-fill-mode: backwards;
  padding: rem-rhythm(0.25);
}

.office-provider-list__item {
  max-width: 25%;
  flex-basis: 25%;
}

@include max-screen(1500px) {
  .office-provider-list__item {
    max-width: 33.33%;
    flex-basis: 33.33%;
  }
}

@include max-screen(875px) {
  .office-provider-list__item {
    max-width: 50%;
    flex-basis: 50%;
  }
}

@include max-screen(650px) {
  .office-provider-list__item {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.office-provider-list__loading {
  color: $gray-600;
  font-family: $meta-normal-font-family;
  text-align: center;
  font-size: rem($font-size-lg);
  padding: rem-rhythm(2) rem-rhythm(0.5);
}
