@import '../../common';

.appointment-form__name-column {
  @include spacing(0.5);
}

@include min-screen($viewport-sm) {
  .appointment-form__name-row {
    display: flex;
  }

  .appointment-form__name-column {
    flex: 1 1 0;
    margin-bottom: 0;
    padding-left: rem-rhythm(0.5);

    &:first-child {
      padding-left: 0;
    }
  }
}

.appointment-form__info {
  @include min-screen($viewport-md-max) {
    display: none;
  }
}
