@import '../common';

:global {
  .color-primary {
    color: $primary-color;
  }

  .color-secondary {
    color: $secondary-color;
  }

  .color-success {
    color: $success-color;
  }

  .color-warning {
    color: $warning-color;
  }

  .color-error {
    color: $error-color;
  }

  .color-muted {
    color: $new-muted-color;
  }

  .color-text {
    color: $text-color;
  }

  .color-strong {
    color: $strong-color;
  }
}
