// ----------------------------------------------------------------------------
// Highlight
// ----------------------------------------------------------------------------

%highlight-base {
  padding-right: rhythm(0.25);
  padding-left: rhythm(0.25);
}

%primary-highlight {
  @extend %highlight-base;
  background: $primary-message-background-color;
  color: $primary-message-text-color;
}

%success-highlight {
  @extend %highlight-base;
  background: $success-message-background-color;
  color: $success-message-text-color;
}

%warning-highlight {
  @extend %highlight-base;
  background: $warning-message-background-color;
  color: $warning-message-text-color;
}

%error-highlight {
  @extend %highlight-base;
  background: $error-message-background-color;
  color: $error-message-text-color;
}

// ----------------------------------------------------------------------------
// Messages
// ----------------------------------------------------------------------------

%message-base {
  @include spacing(1);
  border-radius: $message-border-radius;
  position: relative;
  padding: rhythm(0.5);
  border-width: 1px;
  border-style: solid;

  @include with-feature(generatedcontent) {
    padding-left: rhythm(1.75);
  }

  &:before {
    @extend %icon;
    @include adjust-font-size-to($message-icon-font-size);
    position: absolute;
    top: rhythm(0.375, $message-icon-font-size);
    left: rhythm(0.5, $message-icon-font-size);

    @media screen and (min-width: $tablet-breakpoint) {
      top: rhythm(0.5, $message-icon-font-size);
    }
  }
}

%primary-message {
  @extend %primary-highlight;
  @extend %message-base;
  border-color: $primary-message-border-color;

  &:before {
    @include icon-content('info-sign');
  }
}

%success-message {
  @extend %success-highlight;
  @extend %message-base;
  border-color: $success-message-border-color;

  &:before {
    @include icon-content('ok-sign');
  }
}

%warning-message {
  @extend %warning-highlight;
  @extend %message-base;
  border-color: $warning-message-border-color;

  &:before {
    @include icon-content('alert');
  }
}

%error-message {
  @extend %error-highlight;
  @extend %message-base;
  border-color: $error-message-border-color;

  &:before {
    @include icon-content('alert');
  }
}

%small-message {
  padding-top: rhythm(0.25);
  padding-bottom: rhythm(0.25);

  @include with-feature(generatedcontent) {
    padding-left: rhythm(1.25);
  }

  &:before {
    @include adjust-font-size-to($small-message-icon-font-size);
    top: rhythm(0.25, $small-message-icon-font-size);
    left: rhythm(0.375, $small-message-icon-font-size);
  }
}

// ----------------------------------------------------------------------------
// Titles
// ----------------------------------------------------------------------------

%message-title-base {
  @extend %no-space-bottom;
  @include adjust-font-size-to($h4-font-size, $h4-line-height);

  @media screen and (min-width: $tablet-breakpoint) {
    @include adjust-font-size-to($h3-font-size, $h3-line-height);
  }
}

%primary-message-title {
  @extend %message-title-base;
  color: $primary-message-text-color;
}

%success-message-title {
  @extend %message-title-base;
  color: $success-message-text-color;
}

%warning-message-title {
  @extend %message-title-base;
  color: $warning-message-text-color;
}

%error-message-title {
  @extend %message-title-base;
  color: $error-message-text-color;
}

// ----------------------------------------------------------------------------
// Inverse
// ----------------------------------------------------------------------------

%inverse-message-base {
  border: 0;
  color: $inverse-message-text-color;
}

%inverse-message-title {
  color: $inverse-message-text-color;
}

%inverse-primary-message {
  background-color: $inverse-primary-message-background-color;
}

%inverse-success-message {
  background-color: $inverse-success-message-background-color;
}

%inverse-warning-message {
  background-color: $inverse-warning-message-background-color;
}

%inverse-error-message {
  background-color: $inverse-error-message-background-color;
}
