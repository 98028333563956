@import '../../common';

.reschedule-page__row {
  @include row-width;
  @include row-margin(rem-rhythm(0.5));
  padding-top: rem-rhythm(0.5);
  padding-bottom: rem-rhythm(0.5);
}

.reschedule-page__title {
  @extend %strong;
  @include adjust-font-size-to($h1-font-size, $h1-line-height);
  font-family: $meta-bold-font-family;
  margin: 0 0 rem-rhythm(1);
  max-width: 18em;
}

@include min-screen($viewport-lg) {
  .reschedule-page__row {
    @include row-margin;
    padding-top: rem-rhythm(1);
    padding-bottom: rem-rhythm(1);
  }
}
