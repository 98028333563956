@import '../common';

.form-content {
  @include spacing;
}

.form-content--horizontal {
  @include min-screen($xs-screen) {
    margin-left: calc(30% + #{rem-rhythm(1)});
  }
}
