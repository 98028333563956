@import './common';

// ----------------------------------------------------------------------------
// Openings Calendar
// ----------------------------------------------------------------------------

.openings-calendar {
  position: relative;
  border: 1px solid $border-color;
  border-bottom: 4px solid $border-color;
  border-radius: 4px;
}

// ----------------------------------------------------------------------------
// Openings Calendar > Nav
// ----------------------------------------------------------------------------

%openings-calendar-nav {
  @include gradient-background($data-table-head-background-color);
  position: absolute;
  top: 0;
  z-index: 10;
  display: block;
  width: $calendar-nav-width;
  min-width: 16px;
  height: rem-rhythm(2);
  padding: 0;
  line-height: rem-rhythm(2);
  text-align: center;
  border: 0;
  color: $link-color;
  // box-sizing: content-box;

  &:focus,
  &:hover {
    @include gradient-background($data-table-head-hoverbackground-color);
    color: $link-hover-color;
  }

  &:disabled {
    color: $muted-color;
  }

  &:disabled:focus,
  &:disabled:hover {
    @include gradient-background($data-table-head-background-color);
    color: $muted-color;
    cursor: default;
  }
}

.openings-calendar__previous {
  @extend %openings-calendar-nav;
  left: 0;
}

.openings-calendar__next {
  @extend %openings-calendar-nav;
  right: 0;
}
