// stylelint-disable scss/dollar-variable-pattern

@function generate-color-scale($base-color, $light-mix-color: #fff, $dark-mix-color: #000) {
  @return (
    25: mix($base-color, $light-mix-color, 7%),
    50: mix($base-color, $light-mix-color, 12%),
    100: mix($base-color, $light-mix-color, 30%),
    200: mix($base-color, $light-mix-color, 50%),
    300: mix($base-color, $light-mix-color, 70%),
    400: mix($base-color, $light-mix-color, 85%),
    500: $base-color,
    600: mix($base-color, $dark-mix-color, 85%),
    700: mix($base-color, $dark-mix-color, 70%),
    800: mix($base-color, $dark-mix-color, 50%),
    900: mix($base-color, $dark-mix-color, 30%)
  );
}
