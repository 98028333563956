// stylelint-disable max-nesting-depth

@import '../../common';

$office-marker-size: 4em;
$office-marker-tip-position: 0.91667;

.leaflet-map {
  height: 100%;
}

.marker-icon-container {
  background: none;
  border: 0;
}

.marker {
  width: $office-marker-size;
  height: $office-marker-size;
  text-align: center;
  position: relative;
  z-index: 1;
  transition: all 0.6s;
  transform: scale(1);

  // Position the location icon tip exactly on the correct map coordinates.
  :global(.leaflet-pane) & {
    left: $office-marker-size / -2;
    top: $office-marker-size * -$office-marker-tip-position;
    transform-origin: 50% percentage($office-marker-tip-position);
  }
}

.marker--md.marker--active {
  transform: scale(1.5);
}

.marker__icon {
  display: block;
  color: $blue;
  transition: all 0.4s;

  svg {
    width: $office-marker-size;
    height: $office-marker-size;
    z-index: 1;
  }

  &--active {
    color: $red;
  }
}

.marker__icon__v2 {
  display: block;
  color: $di-blue;
  transition: all 0.4s;

  svg {
    width: $office-marker-size;
    height: $office-marker-size;
    z-index: 1;
  }
}

.marker__number {
  font-size: 1em;
  color: $white;
  font-family: $meta-normal-font-family;
  color: $white;
  text-align: center;
  position: absolute;
  top: 1em;
  left: 0;
  width: 100%;
  z-index: 2;
}
