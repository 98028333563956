@import '../common';

$review-header-icon-size: 30px;
$review-header-photo-size: 60px;

.header {
  background: $blue;
}

.header__container {
  max-width: $reviews-layout-max-width;
  margin: 0 auto;
}

.header__row {
  @include flex;

  flex-flow: row nowrap;
  align-items: center;
  min-height: $review-header-photo-size + $base-line-height;
}

.header__figure {
  padding: rem-rhythm(0.5) 0;
  margin-left: rem-rhythm(0.5);
}

.header__icon {
  @include square($review-header-icon-size);

  color: $white;
}

.header__photo {
  @include circle($review-header-photo-size);

  display: block;
  text-align: center;
  background: $white;
  border: 1px solid $white;
  box-shadow: 0 0 3px 1px darken($blue, 10%);
}

.header__content {
  flex: 1;
  padding: rem-rhythm(0.5);
}

.header__title {
  margin: 0;
  font-family: $meta-bold-font-family;
  font-size: rem($h3-font-size);
  line-height: rem-rhythm(1);
  color: $white;
}

.header__subtitle {
  margin: 0;
  font-size: rem($small-font-size);
  line-height: rem-rhythm(0.75);
  color: $white;
}
